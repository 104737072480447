import * as React from 'react';

import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { ARDataTable, CalloutBanner, PageSeparator, NotationBlock } from '../../components';
import ARSafetyTableMobile from '../../assets/images/AR-Safety-Profile-Table-Mobile.svg';
import ARSafetyTableDesktop from '../../assets/images/AR-Safety-Profile-Table-Desktop.svg';
import SingleARSafetyTableDesktop from '../../assets/images/single_safety_table_desktop.png';
import SingleARSafetyTableMobile from '../../assets/images/vhl-table-safety-mobile.svg';
import * as Text from '../../styles/shared/typography';
import BulletList from '../../components/BulletList';
import * as colors from '../../styles/shared/colors';

// SEO INFORMATION
const pageMeta = {
  title: 'Adverse Reactions for WELIREG™ (belzutifan) | Von Hippel-Lindau Disease',
  keywords: 'welireg for vhl disease adverse reactions, belzutifan for vhl disease adverse reactions',
  description: 'Health care professionals can review adverse reactions in patients with von Hippel-Lindau (VHL) disease–associated tumors receiving WELIREG™ (belzutifan) in LITESPARK-004.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/adverse-reaction-profile/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/adverse-reaction-profile/","@type":"MedicalWebPage","name":"Safety Profile for WELIREG™ (belzutifan)","description":"The median duration of exposure to WELIREG (belzutifan) was 68 weeks (range: 8.4 to 104.7 weeks). Patients on WELIREG experienced a low rate of permanent discontinuation (3.3%) due to ARs. ARs that resulted in permanent discontinuation of WELIREG were dizziness and opioid overdose (1.6% each). Serious adverse reactions occurred in 15% of patients who received WELIREG, including anemia, hypoxia, anaphylaxis reaction, retinal detachment, and central retinal vein occlusion (1 patient each)."}`
  ]
}

const Spacer = styled.div`
  margin-bottom: 64px;
`;

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
  p {
    &:last-child {
      border-bottom: 0;
    }
  }
`;

const calloutBanner = {
  title: 'Learn more about these attributes of WELIREG:',
  continualInvertedButtons: true,
  links: [
    {
      label: 'Mechanism of Action',
      url: '/von-hippel-lindau/mechanism-of-action/',
    },
    {
      label: 'Efficacy',
      url: '/von-hippel-lindau/efficacy/',
    },
    {
      label: 'Guidelines',
      url: '/von-hippel-lindau/guidelines/',
    },
  ],
};

const ARDATA = [
  'The median duration of exposure to WELIREG (belzutifan) was 68 weeks (range: 8.4 to 104.7 weeks).'
  , 'Patients on WELIREG experienced a low rate of permanent discontinuation (3.3%) due to ARs. ARs that resulted in permanent discontinuation of WELIREG were dizziness and opioid overdose (1.6% each).'
  , 'Serious adverse reactions occurred in 15% of patients who received WELIREG, including anemia, hypoxia, anaphylaxis reaction, retinal detachment, and central retinal vein occlusion (1 patient each).',
];

const ARDATA2 = [

];


const ARFooter = {
  footnotes: [
    {
      label: 'a',
      text: 'Graded per NCI CTCAE v4.0.',
    },
    {
      label: 'b',
      text: 'Includes other related terms.',
    },
    {
      label: 'c',
      text: 'Includes visual impairment, vision blurred, central retinal vein occlusion and retinal detachment.',
    },
    {
      label: 'd',
      text: 'The denominator used to calculate the rate is based on all patients in the safety analysis population.',
    },
    {text: <br/>},
    {text: 'AR = adverse reaction; ALT = alanine aminotransferase; AST = aspartate aminotransferase; NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events.'},
  ],
};

const BulletListCopy =  {
  title: 'In the LITESPARK-004 clinical trial:',
  list: [
    ['Decreased hemoglobin occurred in 93% of patients and 7% had Grade 3 events', ['Median time to onset of anemia was 31 days (range: 1 day to 8.4 months)']],
    ['Hypoxia occurred in 1.6% of patients'],
  ],
};

const SafetyBulletedListed = styled(BulletList)`
  div {
    color: ${colors.charcoal};
    font-weight: 400;
  }

`;

const TableTitle = styled(Text.H6)`
  color: ${colors.blueberry};

`;


const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/adverse-reaction-profile/" />
      </PageSeo>

      <PageTitle unbranded>
        Safety Profile for WELIREG™ (belzutifan)
      </PageTitle>

      <Spacer />

      <StandardSection>
        <ARDataTable className="image-responsive" alt="Adverse Reactions Occurring in ≥10% of Patients Treated With WELIREG™ (belzutifan)" body={ARDATA} imgDesktop={ARSafetyTableDesktop}  imgMobile={ARSafetyTableMobile}>
        </ARDataTable >
        <br/>
        <br/>
        <br/>
        <ARDataTable className="image-responsive" alt="Select Laboratory Abnormalities (≥10%) That Worsened From Baseline in Patients Who Received WELIREG in LITESPARK-004" body={ARDATA2}
        imgDesktop={SingleARSafetyTableDesktop}
        imgMobile={SingleARSafetyTableMobile}
        >
          <Text.H3>
          Monitoring for anemia and hypoxia
          </Text.H3>
          <TableTitle>
          Monitor for anemia and oxygen saturation before initiation of, and periodically throughout, treatment with WELIREG.
          </TableTitle>
          <SafetyBulletedListed listTitle={ BulletListCopy.title } listItems={ BulletListCopy.list }/>


        </ARDataTable >
        <Spacer />
        <NotationBlock {...ARFooter}/>

      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='48px' bottomPadding='0px' />
      </StandardSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};


export default IndexPage;
